import React from "react";

export default function Vr() {
  return (
    <div>
      <div className="vr-text">
        <div className="col-md-12">
          <h1 className="head-serv title-socio">Tour virtual</h1>
          <h5 className="head-serv2 subtitle-socio">Visita nuestras instalaciones</h5>
        </div>
      </div>
      <div id="vr">
        <iframe
          src="https://www.google.com/maps/embed?pb=!4v1648718875047!6m8!1m7!1sCAoSK0FGMVFpcE5qZEtiS3FvbUJVV3NtNURUeXdNMDdwNHFIN1E3N1A0b1VEajA.!2m2!1d38.19155595477272!2d-0.5757258202452249!3f340!4f0!5f0.7820865974627469"
          title="tour"
          id="vr"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}
