import React from "react";
import "./Qr.css";
import { Button } from "react-bootstrap";

export default function Qr() {
  return (
    <div>
      <h1 className="title-socio gallery-align-h1">Inscripciones</h1>
      <ul className="list-inscripciones">
        <li>
          <a href="https://windsurfsantapola.playoffinformatica.com/preinscripcion/">
            <Button className="btn-insc" variant="primary">
              Asociado Anual
            </Button>
          </a>
        </li>
        <li>
          <a href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=1">
            <Button className="btn-insc" variant="primary">
              Asociado Deportivo
            </Button>
          </a>
        </li>
        <li>
        <a href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=2">
            <Button className="btn-insc" variant="primary">
              Curso Windsurf
            </Button>
          </a>
        </li>
        <li>
        <a href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=5">
            <Button className="btn-insc" variant="primary">
              Sup Yoga
            </Button>
          </a>
        </li>
        <li>
          <a href="https://windsurfsantapola.playoffinformatica.com/actividad/26/INSCRIPCION-GRUPOS/">
            <Button className="btn-insc" variant="primary">
              Grupos
            </Button>
          </a>
        </li>
        <li>
        <a href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=4">
            <Button className="btn-insc" variant="primary">
              Campus Azul
            </Button>
          </a>
        </li>
      </ul>
    </div>
  );
}
