import React from "react";
import "./About1.css";
import { Card, Accordion } from "react-bootstrap";

export default function About1() {
  return (
    <div>
      <h1 className="head-serv title-socio">Descubre un poco más...</h1>
      <img className="img-about" src="/new/About2.png" alt="about" />
      {/* <img className="img-about" src="/new/club-phone.png" alt="about" /> */}
      <Accordion className="acord-about" defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Card.Title className="title-act-1"> Sobre nosotros</Card.Title>
          </Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <Card.Text>
                  <p className="text-about">
                    Somos el Club de Windsurf Santa Pola, un centro de
                    Actividades Náuticas con Escuela Homologada por la
                    Federación de Vela de la Comunidad Valenciana. En esta web
                    encontraréis toda la información necesaria en cuanto a
                    organización, características y actividades realizadas por
                    este club. Dispone de unas instalaciones a pie de la playa y
                    una gran variedad de secciones importantes para sus
                    asociados y amantes de los deportes náuticos. Cualquier
                    persona podrá entrar a formar parte de nuestra gran familia
                    de la manera más fácil y rápida posible.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="acord-about" defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Card.Title className="title-act-1"> Un poco más</Card.Title>
          </Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <Card.Text>
                  <div className="body-card-about">
                    <p className="text-about list-about">
                      Nuestro currículum cuenta con la organización de grandes
                      pruebas a nivel Autonómico, Nacional e Internacional, como
                      son:
                    </p>
                    <ul className="list-about text-about">
                      <li>
                        Año 2006, Campeonato la Copa de España y el Autonómico
                        de la Comunidad Valenciana de Fórmula Windsurfing.
                      </li>
                      <li>
                        Año 2007, Campeonato de Europa de Fórmula Windsurfing.
                      </li>
                      <li>
                        Año 2009, Campeonato del Mundo, de España y el
                        Autonómico de la Comunidad Valenciana de Fórmula
                        Windsurfing.
                      </li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="acord-about" defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Card.Title className="title-act-1">Junta Directiva</Card.Title>
          </Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <Card.Text>
                  <div className="body-card-about">
                    <ul className="list-about text-about">
                      <li>Vicente Germán Martínez García (PRESIDENTE)</li>
                      <li>Elia Quiles Marcos (VICEPRESIDENTA PRIMERA)</li>
                      <li>Enrique Quesada Soriano (VICEPRESIDENTE SEGUNDO)</li>
                      <li>Lucia Sánchez De La Morena Fernández (SECRETARIA)</li>
                      <li>Daniel Pérez Hernández (TESORERIA)</li>
                      <li>Viviana Mafredini Soave (VOCAL)</li>
                      <li>José Vicente Mallebrera Aldeguer (VOCAL)</li>
                      <li>Sara Redondo Montes (VOCAL)</li>
                      <li>Teresa Blasco Ruso (VOCAL)</li>
                      <li>Belén Fernández Irisarri (VOCAL)</li>
                    </ul>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
