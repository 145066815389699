import React from "react";

export default function CondicionesDeUso() {
  return (
    <div className="privacy-p margin-legacy">
      <h1 className="title-socio gallery-align-h1">Política de Privacidad</h1>
      <p>
        Las condiciones Generales son las establecidas en los documento que
        rigen el funcionamiento de este club, que son: Los Estatutos, el
        Reglamento Interno y las normas de navegación por la Capitanía Marítima,
        así como el conocimiento en el cumplimiento en lo establecido en el Plan
        de Actuación y Contingencia del Club Windsurf Santa Pola.
      </p>
    </div>
  );
}
