import React from 'react'
import FloatingWhatsApp from 'react-floating-whatsapp';

export default function WApp() {
  return (
    <div className="App">
      <FloatingWhatsApp 
        phoneNumber="34640326402"
        accountName="Club Windsurf Santa Pola"
        statusMessage="Responderemos antes de 24h"
        chatMessage="¿En qué podemos ayudarte?"
        placeholder="Escriba su mensaje..."
        darkMode
        avatar="/atencionCliente.png"
      />
    </div>
  )
}