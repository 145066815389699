import React from "react";
import "./Gallery.css";
export default function Gallery() {
  return (
    <div className="body-gallery">
      <div className="container-gallery">
        <h1 className="title-socio gallery-align-h1">Galería</h1>
        <h5 className="head-serv2 subtitle-socio">Para ver más visita nuestras redes sociales</h5>
        <div className="gallery">
          <div className="gallery-item">
            <img
              className="gallery-image"
              src="/new/galeria1.jpg"
              alt="person writing in a notebook beside by an iPad, laptop, printed photos, spectacles, and a cup of coffee on a saucer"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="/new/galeria2.jpg"
              alt="sunset behind San Francisco city skyline"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="/new/galeria3.jpg"
              alt="people holding umbrellas on a busy street at night lit by street lights and illuminated signs in Tokyo, Japan"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="/new/galeria4.jpg"
              alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="/new/galeria5.jpg"
              alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="/new/galeria6.jpg"
              alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
