import React from "react";
import "./Cards.css";

export default function Cards() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title-heading">
              <h1 className="title-socio">Hazte Socio</h1>{" "}
              <h5 className="head-serv2 subtitle-socio">En unos pocos pasos</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title margin-serv-1">ASOCIADO/A ANUAL</h4>
                <div className="card-image">
                  <i className="fas fa-chalkboard-teacher fa-5x"></i>
                </div>
                <h4 className="sub-title-serv margin-serv-2">
                  SOCIO/A DE NÚMERO INDIVIDUAL
                </h4>
                <h6 className="montserrat-h6">
                  Cuota anual 225€
                  <i className="p-size">* 20% en todas las actividades</i>
                </h6>
                <p className="card-text">
                  Siendo socio anual podrás disfrutar de 4 actividades por el
                  precio de una. Podrás practicar Windsurf* Paddlesurf o Kayak , además de entrenar en el
                  gimnasio. El socio/a
                  tendrá una llave y chip para poder tener acceso cuando lo
                  desee.
                  <br />
                  <br />
                  *El material de windsurf se podrá utilizar en caso de tener
                  conocimientos, si no fuera así está la opción de realizar un
                  curso con descuento.
                  <br />
                  *Respetar Normas de <a href="https://www.mitma.gob.es/marina-mercante/nautica-de-recreo/normas-de-seguridad-y-recomendaciones">capitanía marítima</a> y <a href="http://www.salvamentomaritimo.es/mejora-tu-seguridad">salvamento.</a>
                </p>
                <h4 className="sub-title-serv">SOCIO/A DE NÚMERO FAMILIAR</h4>
                <h6 className="montserrat-h6">
                  Cuota anual 275€{" "}
                  <i className="p-size">* 30% en todas las actividades</i>
                </h6>
                <p className="card-text margin-botton">
                  Con esta opción tú y tu unidad familiar* podéis utilizar el
                  material del club y las instalaciones como el asociado/a
                  individual.
                  <br />
                  <br />
                  *La unidad familiar son el cónyuge y los descendientes de 1er
                  grado hasta los 24 años que convivan con el asociado/a
                  titular.
                </p>
                <div className="align-sociosbtn margin-btn-socio">
                  <a href="https://windsurfsantapola.playoffinformatica.com/preinscripcion/">
                    {" "}
                    <button className="searchButton marg-search-btn">
                      Solicitud
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  SECCIONES Y<br /> MODALIDADES
                </h4>
                <div className="card-image">
                  <i className="fas fa-bullseye fa-5x"></i>
                </div>
                <h4 className="sub-title-serv">CONSIGNA DE MATERIAL</h4>
                <p className="card-text estrechar-texto">
                  <b>Sólo los socios anuales</b> podrán dejar en el Club el
                  material de su propiedad según las secciones y modalidades
                  siguientes:
                </p>
                <ul className="montserrat">
                  <li>
                    <b>Windsurf 1º Modalidad:</b> 1 tabla, 2 velas, 2 mástiles y
                    2 botavaras. Por un importe de 50€.{" "}
                  </li>
                  <li>
                    <b>Windsurf 2º Modalidad:</b> 2 tablas, 5 velas, 3 mástiles,
                    3 botavaras. Por un importe de 100€.
                  </li>
                  <li>
                    <b>Modalidad Kayak:</b> 1 piragua, 1 remo. Por un importe de
                    100€.
                  </li>
                  <li>
                    <b>Modalidad Foil:</b> 1 aleta de foil. Por un importe de
                    25€.
                  </li>
                  <li>
                    <b>Modalidad Paddle Surf:</b> 1 paddle surf, 1 pala. Por un
                    importe de 50€.
                  </li>
                  <li>
                    <b>Modalidad Wing:</b> 1 tabla, 2 alas. Por un importe de
                    50€.
                  </li>
                </ul>
                <p className="estrechar-texto">
                  *Los asociados sólo podrán elegir una modalidad por sección.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  ASOCIADO/A
                  <br /> DEPORTIVO/A
                </h4>
                <div className="card-image">
                  <i className="fas fa-laptop-code fa-5x"></i>
                </div>
                <h4 className="sub-title-serv">SOCIO DEPORTIVO</h4>
                <ul className="montserrat margin-botton1">
                  <li>
                    El socio deportivo solo tiene derecho a utilizar los
                    materiales de navegación del club, por el tiempo aproximado
                    de <b>2 horas.</b>
                  </li>
                  <li>
                    El precio de la actividad es de <b>20€.</b>
                  </li>
                  <li>
                    Si desea un monitor, precio adicional de 20€/ persona y
                    hora, tras confirmación y disponibilidad.
                  </li>
                  <li>
                    Podrá acceder a los aseos y al vestuario de la zona social.
                  </li>
                </ul>
                <p>Normas de uso materiales:</p>
                <ul className="normas montserrat-normas">
                  <li>
                    El tiempo transcurrido del uso del material será alrededor
                    de 2 horas.
                  </li>
                  <li>
                    Se le comunicará recomendaciones sobre el uso del material
                    para el buen cuidado y mantenimiento y de las medidas de
                    seguridad exigidas al centro. De no cumplir con lo anterior,
                    el coordinador/a o asociado/as responsables del centro
                    procederán a la retirada del material utilizado.
                  </li>
                  <li>
                    El asociado deportivo será responsable de los daños
                    ocasionados a los materiales.Respetar las normas vigentes de <a href="https://www.mitma.gob.es/marina-mercante/nautica-de-recreo/normas-de-seguridad-y-recomendaciones">capitanía marítima</a> y <a href="http://www.salvamentomaritimo.es/mejora-tu-seguridad">salvamento.</a>
                  </li>
                  <li>
                    Para el uso de material de windsurf es necesario haber
                    practicado dicho deporte con anterioridad y tener
                    conocimientos básicos.
                  </li>
                </ul>
                <div className="align-sociosbtn ">
                  <a href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=1">
                    {" "}
                    <button className="searchButton marg-search-btn2">Solicitud</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
