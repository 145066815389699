import React from "react";
import "./ActNaut.css";
import { Card, Accordion } from "react-bootstrap";
export default function Campus() {
  return (
    <div>
      <div className="containerAN">
        <section className="banner-module">
          <div className="banner banner-four-campus">
            <span className="headline">
              CAMPUS <br />
              AZUL
            </span>
            {/* <span className="subline">Para todas las edades y niveles</span> */}
          </div>

          <div className="banner banner-one">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">Semana</Card.Title>
                <Card.Text>
                  <ul className="list-act">
                    <li>
                      <b>Duración:</b>Lunes a Viernes{" "}
                    </li>
                    <div className="align-tachado">
                      <li className="price tachado">75€</li>
                      <li className="price">ㅤ52.5€</li>
                    </div>
                  </ul>
                </Card.Text>
                <a
                  className="button-act-align"
                  href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=4#"
                >
                  {" "}
                  <button className="button-act">Solicitud</button>
                </a>
              </Card.Body>
            </Card>
          </div>

          <div className="banner banner-two">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">Mes</Card.Title>
                <Card.Text>
                  <ul className="list-act">
                    <li>
                      <b>Duración:</b>Cualquier mes (Julio o Agosto)
                    </li>
                    <div className="align-tachado">
                      <li className="price tachado">275€</li>
                      <li className="price">ㅤ192.5€</li>
                    </div>
                  </ul>
                </Card.Text>
                <a
                  className="button-act-align"
                  href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=4#"
                >
                  {" "}
                  <button className="button-act">Solicitud</button>
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="banner banner-two">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">Temporada</Card.Title>
                <Card.Text>
                  <ul className="list-act">
                    <li>
                      <b>Duración:</b>Julio y <br />
                      Agosto
                    </li>
                    <div className="align-tachado">
                      <li className="price tachado">440€</li>
                      <li className="price">ㅤ308€</li>
                    </div>
                  </ul>
                </Card.Text>
                <a
                  className="button-act-align"
                  href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=4#"
                >
                  {" "}
                  <button className="button-act">Solicitud</button>
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="banner banner-two">
            <Accordion className="accordion-margin" defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {" "}
                  <Card.Title className="title-act-1">
                    {" "}
                    Descripción Actividad
                  </Card.Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        {/* <p className="p-act-2">SOLO PARA MAYORES DE EDAD</p> */}
                        <p className="p-act-2">
                          Se distribuye en dos actividades al día con un
                          descanso para almorzar, beber agua y ponerse crema.
                        </p>
                        <p className="p-act-2">
                          Hay diversas actividades, tales como Kayak, Windsurf,
                          Paddle Surf, Big Sup y juegos en la arena o agua.
                        </p>
                        <p className="p-act-2">
                          *Nos adaptamos al ritmo de aprendizaje de cada
                          persona.
                        </p>
                        <p className="p-act-2">
                          *La realización de las actividades puede variar en
                          función de la meteorología.
                        </p>
                        <br />
                        <ul className="list-act">
                          <li className="p-act-2">
                            ¿Qué incluye la actividad? :
                          </li>
                          <li className="p-act-2">
                            Material necesario para el desarrollo de la
                            actividad
                          </li>
                          <li className="p-act-2">Monitores titulados</li>
                          <li className="p-act-2">
                            Clases teóricas y prácticas
                          </li>
                          <li className="p-act-2">Licencia de Escuela</li>
                        </ul>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="clear-fix"></div>
        </section>
      </div>
    </div>
  );
}
