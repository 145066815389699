import React from "react";

export default function Privacidad() {
  return (
    <div className="privacy-p">
      <h1 className="title-socio gallery-align-h1">Política de Privacidad</h1>
      <h5 className="head-serv2 subtitle-socio">POLÍTICA DE PRIVACIDAD</h5>
      <p>
        A efecto de lo previsto en la Ley Orgánica 15/1999 de 13 de diciembre de
        Protección de Datos de Carácter Personal y el Reglamento General de
        Protección de Datos de la Unión Europea 2016/679 de 27 de abril de 2016,
        el CLUB WINDSURF SANTA POLA, con CIF G53528943, informa al usuario de la
        existencia del tratamiento de los datos de carácter personal creado con
        la finalidad de realizar la gestión de la relación con las personas
        usuarias, clientes y potenciales para información de comunicaciones y
        gestiones sobre productos y/o servicios de interés para el usuario.
      </p>
      <h5 className="head-serv2 subtitle-socio">ENVÍO Y REGISTRO DE DATOS DE CARÁCTER PERSONAL</h5>
      <p>
        El envío de datos de carácter personal es obligatorio para contactar y
        recibir información sobre los productos, servicios e información del
        CLUB WINDSURF SANTA POLA, en adelante debiendo suscribirse expresamente
        en nuestra newsletter si quiere que le mantengamos informado
        periódicamente de información y novedades. El no facilitar los datos
        personales solicitados o el no aceptar la presente política de
        protección de datos supone la imposibilidad de suscribirse, registrarse
        o recibir información de este Club. De acuerdo a lo establecido en la
        Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de
        Carácter Personal, y Reglamento General de Protección de Datos de la
        Unión europea 2016/679 de 27 de abril de 2016 relativo a la protección
        de las personas físicas en lo que respecta al tratamiento de datos
        personales y a la libre circulación de estos datos le informamos que los
        datos personales que se obtengan como consecuencia de su envío de datos
        personales, serán objeto de tratamiento por El CLUB WINDSURF SANTA POLA
        con domicilio en la Avenida Vicente Blasco Ibañez número 55, 03130 Santa
        Pola (Alicante), teniendo implementadas todas las medidas de seguridad
        establecidas en el Real Decreto 1720/2007.
      </p>
      <h5 className="head-serv2 subtitle-socio">EXACTITUD Y VERACIDAD DE LOS DATOS FACILITADOS</h5>
      <p>
        La persona usuaria que envía la información a este CLUB es la única
        responsable de la veracidad y corrección de los datos incluidos,
        exonerándose el CLUB de cualquier responsabilidad al respecto. Las
        personas usuarias garantizan y responden, en cualquier caso, de la
        exactitud, vigencia y autenticidad de los datos personales facilitados y
        se comprometen a mantenerlos debidamente actualizados. Las personas
        usuarias aceptan proporcionar información completa y correcta en el
        formulario de registro o suscripción. El CLUB WINDSURF SANTA POLA no
        responde de la veracidad de las informaciones que no sean de elaboración
        propia y de las que se indique otra fuente, por lo que tampoco asume
        responsabilidad alguna en cuanto a hipotéticos perjuicios que pudieran
        originarse por el uso de dicha información. Se exonera a el CLUB de
        responsabilidad ante cualquier daño o perjuicio que pudieran sufrir las
        personas usuarias como consecuencia de errores, defectos u omisiones, en
        la información facilitada por el CLUB siempre que proceda de fuentes
        ajenas al mismo.
      </p>
      <h5 className="head-serv2 subtitle-socio">FINALIDADES</h5>
      <p>
        Las finalidades de la recogida de datos son: el desarrollo de
        actividades y prestación de información sobre productos y posterior
        puesta en contacto con el usuario del CLUB.
      </p>
      <h5 className="head-serv2 subtitle-socio">CESIÓN DE DATOS A TERCEROS</h5>
      <p>
        El CLUB WINDSURF SANTA POLA no realizará cesión de los datos de las
        personas usuarias a terceros. No obstante, en el caso de ser cedidos a
        alguna empresa se produciría una información previa a la recogida
        solicitando el consentimiento expreso del afectado.EJERCICIO DE DERECHOS
        DE ACCESO, RECTIFICACIÓN, SUPRESIÓN Y OPOSICIÓN, LIMITACIÓN DEL
        TRATAMIENTO, PORTABILIDAD DE DATOS (ART. 15, 16, 17, 18, 19, 20, 21, 22
        Y 23 RGPD) Podrá dirigir sus comunicaciones, revocar el consentimiento
        prestado y ejercitar el derecho de acceso a sus datos personales
        obteniendo copia de los mismos. El derecho de rectificación de datos
        inexactos o incompletos. El derecho de supresión de sus datos
        personales. El derecho de retirada de sus datos personales de su página
        web. El derecho de oposición al tratamiento de sus datos personales. El
        derecho de limitación del tratamiento de sus datos personales. Y el
        derecho a la portabilidad de sus datos personales automatizados por
        correo: dirigido a CLUB WINDSURF SANTA POLA con domicilio en la Avenida
        Vicente Blasco Ibañez número 55, 03130 Santa Pola (Alicante). Para
        ejercer dichos derechos y en cumplimiento de la Instrucción 1/1998, de
        19 de enero, de la Agencia Española de Protección de Datos, es necesario
        que usted acredite su personalidad frente al CLUB mediante el envío de
        fotocopia de Documento Nacional de Identidad o cualquier otro medio
        válido en Derecho. Los interesados tienen derecho a reclamar ante la
        autoridad de control.
      </p>
      <h5 className="head-serv2 subtitle-socio">PLAZO DE CONSERVACIÓN DE LOS DATOS</h5>
      <p>
        Los datos personales de las personas usuarias serán tratados de forma
        activa mientras ostente la condición de Usuario/a de
        www.windsurfsantapola.es y .com; es decir, mientras no revoque su
        consentimiento o no ejerza su derecho de cancelación, supresión u
        oposición a alguna finalidad en concreto. En caso de revocar su
        consentimiento o ejercer los derechos de cancelación o supresión, sus
        datos serán bloqueados y conservados de forma restringida a disposición
        de las autoridades competentes durante un plazo de 3 años para atender a
        las posibles responsabilidades nacidas del tratamiento.
      </p>
      <h5 className="head-serv2 subtitle-socio">LEGITIMACIÓN PARA EL TRATAMIENTO DE LOS DATOS</h5>
      <p>
        El registro en www.windsurfsantapola.es y .com es libre y voluntario
        para las personas usuarias, por lo que la base legitimadora del
        tratamiento de sus datos personales es su consentimiento.
      </p>
      <h5 className="head-serv2 subtitle-socio">ACEPTACIÓN Y CONSENTIMIENTO</h5>
      <p>
        El uso de la web le otorga la condición de persona usuaria del mismo, e
        implica la aceptación plena y sin reservas de todas y cada una de las
        disposiciones incluidas en las condiciones sobre protección de datos de
        carácter personal, aceptando y consintiendo el tratamiento de los mismos
        por parte de www.windsurfsantapola.es y .com , en la forma y para las
        finalidades indicadas en la presente Política de Protección de Datos
        Personales, en la versión publicada por la “web”, en el momento mismo en
        que la persona usuaria acceda a la misma. Como consecuencia, es deber de
        la persona usuaria leer detenidamente el presente aviso legal cada vez
        que utilice la Web, debido a que esta puede sufrir modificaciones,
        siendo conocedor de toda la información que posee la web en su
        referencia en especial al coronavirus Covid-19, teniendo la
        obligatoriedad de su cumplimiento por la seguridad general de todos ante
        esta situación de alerta sanitaria y cumplira con lo indicado en su Plan
        de Contingencia. En relación con la oferta de servicios de la sociedad
        de la información directamente a un niño, el tratamiento de los datos
        personales de un niño será lícito cuando éste tenga al menos 16 años de
        edad. Cuando el niño sea menor de 16 años, dicho tratamiento sólo será
        legal si el titular de la responsabilidad parental sobre el niño da o
        autoriza su consentimiento y en la medida en que lo dé o autorice
        indicado en la web en el apartado de solicitud de asociado o de la
        actividad; cursos, campus, etc.. al igual el poder hacer fotos y videos
        durante las actividades y pudieran publicarlas en los medios de
        comunicación siempre expresa del propio Club Windsurf Santa Pola. El
        derecho a la imagen está reconocido en el articulo 18.1 de la
        Constitución y regulado por la Ley Organica 1/1982, de 5 de mayo, de
        protección del derecho al honor intimidadpersonal y familiar y la propia
        imagen.
      </p>
      <h5 className="head-serv2 subtitle-socio">Declaración Responsable de la actividad.</h5>
      <p>
        El abajo firmante manifiesta, bajo su responsabilidad, que los datos
        consignados en la presente declaración son ciertos y que cumple con los
        requisitos establecidos en la legislación para el ejercicio de la
        navegación, que dispone de la documentación que así lo acredita y que se
        compromete a mantener su cumplimiento durante el tiempo inherente a
        dicho reconocimiento o ejercicio. El declarante conoce, acepta y se
        compromete a cumplir, además de las prescripciones que a continuación se
        expresan, las instrucciones, requisitos y limitaciones que se recogen en
        los estatutos y el reglamento interno, así como las normas de navegación
        establecidas por Capitanía de marítima y que se encuentran actualizadas y
        disponibles en el dominio Web http://www.windsurfsantapola.com El
        declarante conoce de la peligrosidad inherente al ejercicio de la
        navegación por lo que se compromete a controlar en todo momento su
        embarcación y asume los riesgos y la responsabilidad que puedan resultar
        de la misma. Que acepte la presente y surta efecto, previo a los
        trámites a que haya lugar La inexactitud, falsedad u omisión de datos
        esenciales en esta declaración responsable, o el incumplimiento de los
        requisitos exigibles según la legislación vigente para la actividad a
        que se refiere, determinará la imposibilidad de continuar con su
        ejercicio, sin perjuicio de las responsabilidades penales, civiles o
        administrativas a que hubiera lugar. En su caso, podrá acordarse la
        obligación de restituir la situación jurídica al momento previo, así
        como la imposibilidad de instar un nuevo procedimiento con el mismo
        objeto durante el período de tiempo que se determine. De conformidad con
        la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de
        Carácter personal, se le informa que los datos facilitados se incluirán
        en un fichero, cuyo responsable es el Club Windsurf Santa Pola, a quién
        podrá dirigirse para ejercer los derecho de acceso, rectificación,
        cancelación y oposición.
      </p>
      <h5 className="head-serv2 subtitle-socio">Sistema de Videovigilancia.</h5>
      <p>
        Recordamos a todos los usuarios y asociados de la presencia de cámaras
        de seguridad de vidiovigilancia conectada a la centra de alarmas en el
        interior y exterior perimetral del club, al igual de la instalación
        existente de carteles informativos. Le recordamos que sus datos
        personales están incluidos en un fichero titularidad del Club Windsurf
        de Santa Pola, y que son tratados de conformidad con la normativa
        reguladora de protección de datos con la finalidad de realizar
        actividades de marketing, promoción y comercialización de sus productos
        o servicios y de comunicar los eventos que se organicen. Puede ejercer
        sus derechos de acceso, rectificación, cancelación y oposición, en los
        términos recogidos en la Ley Orgánica de Protección de Datos de Carácter
        Personal, dirigiéndose al Club de Windsurf Santa Pola a través de correo
        electrónico a info@windsurfsantapola.com
      </p>
      <h5 className="head-serv2 subtitle-socio">Nota importante sobre el tratamiento de datos y Google Analytics</h5>
      <p>
        Esta página web utiliza Google Analytics, un servicio de análisis web de
        Google Ireland Limited. En caso de que el responsable del tratamiento de
        datos sobre esta página web resida en un país fuera del Espacio
        Económico Europeo o Suiza, el tratamiento de datos de Google Analytics
        se realiza a través de Google LLC. Google LLC y Google Ireland Limited
        serán referidos en lo sucesivo como “Google”. Google Analytics utiliza
        las llamadas “Cookies”, archivos de texto que se almacenan en el
        dispositivo del visitante de la página y que posibilitan un análisis del
        uso de la página web del visitante. La información generada por la
        Cookie sobre el uso de esta página web (incluyendo la dirección IP
        acortada) se transmite y se almacena normalmente por Google. Google
        Analytics se utiliza en esta página web únicamente con la extensión
        "_anonymizeIp()". Esta extensión asegura la anonimización de la
        dirección IP mediante el acortamiento y excluye cualquier referencia
        personal directa. Mediante la extensión, la dirección IP del visitante
        es acortada previamente por Google dentro de los países miembros de la
        Unión Europea o en otros países parte del acuerdo del Espacio Económico
        Europeo. Solo en casos excepcionales se transmite la dirección IP
        completa a un servidor de Google en EE.UU. y, una vez allí, es acortada.
        La dirección IP transmitida por el navegador correspondiente, en el
        marco de las acciones de Google Analytics, no se combina con otros datos
        de Google. En nombre del dueño de la página, Google utilizará la
        información generada para analizar el uso de la página, realizar
        informes sobre las actividades de la página web y para llevar a cabo
        otros servicios relacionados con el uso de Internet o de la página web
        para el dueño de la página (Art. 6 apart. 1 letra f RGPD). El interés
        legítimo del tratamiento de datos radica en la optimización de la página
        web, el análisis del uso de la página web y la adaptación del contenido.
        Los intereses del usuario están garantizados adecuadamente mediante la
        pseudominización de sus datos. Google LLC garantiza el mantenimiento de
        un nivel adecuado de protección de datos en base a las cláusulas
        contractuales estándares europeas. La información enviada y vinculada a
        las cookies de Google Analyticas, p.ej., las ID de usuarios o los
        identificadores de publicidad se eliminarán automáticamente tras 50
        meses. La supresión de los datos que alcanzan su período de retención se
        realiza de manera automática una vez al mes. El visitante de la página
        web puede rechazar el uso de cookies configurando los ajustes
        correspondientes en su navegador. Asimismo, el visitante puede impedir
        la recopilación de información mediante cookies (incluida su dirección
        IP) y su procesamiento descargando e instalando el siguiente plugin en
        su navegador: http://tools.google.com/dlpage/gaoptout El visitante de la
        página puede desactivar el uso de datos de Google Analytics haciendo
        clic en este enlace. A continuación, se activará una Opt-Out-Cookie que
        bloqueará el uso de sus datos al visitar esta página web. Más
        información sobre el uso de datos por Google, las opciones de
        configuración y anulación se puede encontrar en la Política de
        Privacidad de Google (https://policies.google.com/privacy?hl=es), así
        como en los Ajustes para la publicidad de
        Google(https://adssettings.google.com/authenticated).
      </p>
    </div>
  );
}
