import React, { Component } from 'react'
import './Home.css';
export default class Home extends Component {
    render() {
        return (
            <div className="body-home">
<div className="content">
    <img className="logo-home" src="/new/logo-grande.png" alt="logo"/>
<h2 className="h2-home">Aprende y disfruta</h2>
<div className="arrow bounce">
</div>
 <section className="button"> 
<div className="searchBox">
<a href="/socio"> <button className="searchButton" >HAZTE SOCIO</button></a>
</div>
</section>
</div>

            </div>
        )
    }
}
