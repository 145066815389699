import React from "react";
import "./ActNaut.css";
import { Card,Accordion } from "react-bootstrap";

export default function Yoga() {
  return (
    <div>
      <div className="containerAN">
        <section className="banner-module">
          <div className="banner banner-four-yoga">
            <span className="headline">
              SUP <br />
              YOGA
            </span>
         
          </div>
          <div className="banner banner-three-title">
            <h1 className="title-socio title-none">SUP YOGA</h1>
          </div>

          <div className="banner banner-one">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">
                  CURSO DE <br />1 HORA
                </Card.Title>
                <Card.Text>
                  <ul className="list-act">
                    <li>
                      <b className='duracion'>Duración:</b>
                      <br /> 1 día – 1 hora
                    </li>
                    <li>
                      <b className='duracion'>Tamaño grupo:</b>
                      <br /> 1- 8 pers.
                    </li>
                    <li className="price">
                      <b>15€</b>
                    </li>
                  </ul>
                </Card.Text>
                <a className="button-act-align" href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=5"> <button className="button-act" >Solicitud</button></a>
              </Card.Body>
            </Card>
            <p className="astAN">
              * En todas las actividades a los asociados/as individuales se les
              aplicará un descuento del 20%, y a los familiares un 30%
              <a href="/socio"> Hazte socio aquí</a>
            </p>
          </div>

          <div className="banner banner-two">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">
                  DESCRIPCIÓN <br />
                  DEL CURSO
                </Card.Title>
                <Card.Text>
                  <ul className="lista-grupos">
                    <li>
                      Disfruta con nuestra profesora, especializada en la
                      materia y prueba esta experiencia.
                    </li>
                    <li>
                      Sesiones en grupos y para todos los niveles.
                    </li>
                    <li className="none-yoga">La mejor forma de conciliar cuerpo y mente.</li>
                    <li>Consulta horarios y disponibilidad.</li>
                  </ul>
                </Card.Text>
             
                <a className="button-act-align" href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=5"> <button className="button-act" >Solicitud</button></a>

              </Card.Body>
            </Card>
            <p className="astAN">
              * En todas las actividades a los asociados/as individuales se les
              aplicará un descuento del 20%, y a los familiares un 30%
              <a href="/socio"> Hazte socio aquí</a>
            </p>
          </div>

          <div className="clear-fix"></div>

          <div className="banner banner-three">
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Card.Title className="title-act-1">
                    {" "}
                    Beneficios del SUP YOGA
                  </Card.Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <ul>
                        <p className="p-act-1">- Respetar normas vigentes de <a href="https://www.mitma.gob.es/marina-mercante/nautica-de-recreo/normas-de-seguridad-y-recomendaciones">capitanía marítima</a> y <a href="http://www.salvamentomaritimo.es/mejora-tu-seguridad">salvamento.</a></p>
                          <p className="p-act-1">
                            - Mejora la respiración y el sistema inmunológico.
                          </p>
                          <p className="p-act-1">
                            - Equilibra el sistema nervioso.
                          </p>
                          <p className="p-act-1">
                            - Aumenta la fuerza, la flexibilidad, la
                            coordinación, la estabilidad y la resistencia.
                          </p>
                          <p className="p-act-1">
                            - Y sobre todo fortalece el "core".
                          </p>
                        </ul>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </section>
      </div>
    </div>
  );
}
