import React from 'react'
import './ActNaut.css';
import {Card,Accordion} from 'react-bootstrap'

export default function Grupos() {
    return (
        <div>
           <div className="containerAN">
        <section className="banner-module">
        <div className="banner banner-four-grupo">

<span className="headline">ACTIVIDAD EN <br/>GRUPOS</span>
{/* <span className="subline">Para todas las edades y niveles</span> */}
</div>
<div className="banner banner-three-title">
  
<h1 className="title-socio title-none">GRUPOS</h1>

            </div>
            <div className="banner banner-one">

            <Card>
  <Card.Body>
    <Card.Title className="title-act">CURSO DE <br/>3 HORAS</Card.Title>
    <Card.Text>
      <ul className="list-act">
        <li><b className='duracion'>Duración:</b><br/> 3 horas</li>
        <li><b className='duracion'>Tamaño grupo:</b><br/>Mínimo 10 pers.</li>
        <li className="price"><b>20€ pers.</b></li>
      </ul>
    </Card.Text>
    <a className="button-act-align" href="https://windsurfsantapola.playoffinformatica.com/actividad/26/INSCRIPCION-GRUPOS/"> <button className="button-act" >Solicitud</button></a>

  </Card.Body>
</Card>
            </div>


            <div className="banner banner-two">
            <Card>
            <Card.Body>
  <Card.Title className="title-act">QUÉ INCLUYE<br/>LA ACTIVIDAD</Card.Title>
    <Card.Text>
      <ul className="lista-grupos">
        <li>Material necesario para la práctica náutica, chalecos salvavidas, neopreno, etc.</li>
        <li>Licencia de escuela.</li>
        <li>Monitores acorde al número de participantes en la actividad.</li>
      </ul>
    </Card.Text>
    <a className="button-act-align" href="https://windsurfsantapola.playoffinformatica.com/actividad/26/INSCRIPCION-GRUPOS/"> <button className="button-act" >Solicitud</button></a>
  </Card.Body>
</Card>
            </div>
            
            <div className="clear-fix"></div>
            

            <div className="banner banner-three">
            <Accordion defaultActiveKey="1">
  <Accordion.Item eventKey="0">
    <Accordion.Header ><Card.Title className="title-act-1"> Descripción del Curso</Card.Title></Accordion.Header>
    <Accordion.Body>
    <Card>
  <Card.Body>
    <Card.Text>
    <p className="p-act-1">* SOLO PARA MAYORES DE EDAD</p>
    <p className="p-act-1">* Respetar normas vigentes de <a href="https://www.mitma.gob.es/marina-mercante/nautica-de-recreo/normas-de-seguridad-y-recomendaciones">capitanía marítima</a> y <a href="http://www.salvamentomaritimo.es/mejora-tu-seguridad">salvamento.</a></p>
      <p className="p-act-1">Nuestro objetivo es que los participantes se diviertan practicando deportes náuticos en equipo. Ideal para celebrar cumpleaños, despedidas, etc.
Consulta para horario, disponibilidad y distribución de las actividades.</p>
    </Card.Text>
  </Card.Body>
</Card>
    </Accordion.Body>
  </Accordion.Item>
  </Accordion>
            </div>

    
           
        </section>
    </div>
        </div>
    )
}
