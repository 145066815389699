import React from "react";
import "./Private.css";

export default function Sociosprivado() {
  return (
    <div>
      <h1 className="title-socio gallery-align-h1">ZONA DE SOCIOS</h1>
      <div className="body-priv">
        <h5 className="subtitle-priv">
          PORTAL DE TRANSPARENCIA Y PARTICIPACIÓN
        </h5>

        <p>
          Con motivo de la publicación y entrada en vigor de la Ley 19/2013 de 9
          de diciembre de transparencia, acceso a la información pública y buen
          gobierno, el Club Windsurf Santa Pola, expone la siguiente información
          como continuidad de su política de comunicación abierta.
        </p>

        <h5 className="subtitle-priv">INFORMACIÓN INSTITUCIONAL</h5>
        <p>
          El Club Windsurf Santa Pola, fundado en la ciudad de Santa Pola, se
          crea oficialmente el día 9 de noviembre de 2000, según consta en la
          escritura pública y está adscrito a la Real Federación de Vela
          Española y a la Federación de Vela de la Comunidad Valenciana, en el
          número de Registro de Entidades Deportivas de la Comunidad Valenciana,
          con el número 5737 de la sección primera.
        </p>
        <h5 className="subtitle-priv">
          {" "}
          PARTICIPACIÓN ASOCIADOS, DEJANOS TUS INQUIETUDES O ACTUALIZA TUS DATOS
        </h5>

        <a
          className="footer-call-to-action-button button blue"
          href="mailto:info@windsurfsantapola.com"
          target="_self"
        >
          Mandar Email
        </a>
        <hr />
    <h5 className="subtitle-priv">ENTIDAD BANCARIA</h5>
        <div className="credit-card">
          <img
            src="https://brandlogos.net/wp-content/uploads/2022/02/banco_sabadell-logo-brandlogos.net_.png"
            className="logo"
            alt="credit"
          />
          <div className="numbers">ES66 0081 1220 9900 0112 8723</div>
          <div className="name-and-expiry">
            <span>BSAB ESBB</span>
          </div>
        </div>
        <hr />
        <h5 className="subtitle-priv">DOCUMENTOS DE INTERÉS SOCIOS</h5>
        {/* <ul className="list-priv">
          <li>
            <a
              href="/doc/ESTATUTOS CLUB DEPORTIVO CWSP 2021.pdf"
              download="ESTATUTOS CLUB WINDSURF SANTA POLA 2021"
            >
              ESTATUTOS CLUB WINDSURF SANTA POLA 2021
            </a>
          </li>
          <li>
            <a
              href="/doc/ESTATUTOS CLUB DEPORTIVO CWSP 2013.pdf"
              download="ESTATUTOS CLUB WINDSURF SANTA POLA 2013"
            >
              ESTATUTOS CLUB WINDSURF SANTA POLA 2013
            </a>
          </li>
        </ul>
        <h5 className="subtitle-priv">REGLAMENTO INTERNO</h5>
        <ul className="ul-docs">
          <li>
            <a href="/doc/REGLAMENTO INTERNO.pdf" download="REGLAMENTO INTERNO">
              REGLAMENTO INTERNO
            </a>
          </li>
        </ul>
        <h5 className="subtitle-priv">SEGUROS</h5>
        <ul className="ul-docs">
          <li>
            <a
              href="/doc/Poliza Actividad Nautica.pdf"
              download="Poliza Actividad Nautica"
            >
              Poliza Actividad Nautica
            </a>
          </li>
          <li>
            <a
              href="/doc/POLIZA ACTUALIZADA  Nº 3300444.pdf"
              download="PÓLIZA DE SEGURO RC"
            >
              PÓLIZA DE SEGURO RC
            </a>
          </li>
          <li>
            <a
              href="/doc/POLIZA ACUTALIZADA.pdf"
              download="PÓLIZA MULTI INDUSTRIA"
            >
              REGLAMENTO INTERNO
            </a>
          </li>
          <li>
            <a
              href="/doc/Poliza Edificio Club.pdf"
              download="PÓLIZA EDIFICIO SEDE CLUB"
            >
              PÓLIZA EDIFICIO SEDE CLUB
            </a>
          </li>
          <li>
            <a
              href="/doc/seguro zodiac.pdf"
              download="PÓLIZA EMBARCACION ZODIAC"
            >
              PÓLIZA EMBARCACION ZODIAC
            </a>
          </li>
        </ul>
        <h5 className="subtitle-priv">CONTRATOS</h5>
        <ul className="list-priv">
          <li>
            <a
              href="/doc/Contrato Alquiler Local 2018.pdf"
              download="CONTRATO DE ALQUILER LOCAL CLUB 2018-2023"
            >
              CONTRATO DE ALQUILER LOCAL CLUB 2018-2023
            </a>
          </li>
        </ul> */}
           <a
          className="footer-call-to-action-button button blue"
          href="https://drive.google.com/drive/folders/1FmZIF2eNBtAO1tq-K9WICKBMGoJlyQs8?usp=sharing"
          target="_self"
        >
          Pulsar aquí para ver documentación
        </a>
      </div>
    </div>
  );
}
