import React, { useState } from 'react';
import {Nav,Container,Navbar,NavDropdown,Modal,Button} from 'react-bootstrap';
import Private from '../Private/Private';
export default function NavWind() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
        <>
  <Navbar className="resp-nav" bg="light" expand="lg">
  <Container fluid>
    <Navbar.Brand href="/"><img src="/new/logo-web.png" width="120" alt="logo"/></Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
   
      >
        <Nav.Link href="/socio">Hazte Socio</Nav.Link>
          <Nav.Link href="/servicios">Servicios Socio</Nav.Link>

        <NavDropdown title="Actividades Naúticas" id="navbarScrollingDropdown">
          <NavDropdown.Item href="/cursoWindSurf">Curso Windsurf</NavDropdown.Item>
          <NavDropdown.Item href="/yoga">SUP Yoga</NavDropdown.Item>
          <NavDropdown.Item href="/grupos">Grupos</NavDropdown.Item>
          <NavDropdown.Item href="/campus">Campus</NavDropdown.Item>

          </NavDropdown>
        <Nav.Link href="/meteo">Zona Meteo</Nav.Link>
        <NavDropdown title="Sobre Nosotros" id="navbarScrollingDropdown">
          <NavDropdown.Item href="/sobrenosotros">Quiénes somos</NavDropdown.Item> 
          <NavDropdown.Item href="/staff">Staff</NavDropdown.Item>
          <NavDropdown.Item href="/galeria">Galería</NavDropdown.Item>
          <div className="align-nav-vr">
          <NavDropdown.Item href="https://www.instagram.com/windsurfsantapola/?igshid=YmMyMTA2M2Y="><img className="insta-align" src="/insta.png" alt="insta" width="32" /></NavDropdown.Item>
          <NavDropdown.Item href="tourVirtual">  <img src="/vr.png" alt="insta" width="80" /></NavDropdown.Item>
          </div>
          <NavDropdown.Item ><Button variant="primary" className="btn-private" onClick={handleShow}>
          Zona de socios
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Accede a la zona de Socios</Modal.Title>
          </Modal.Header>
          <Modal.Body><Private/></Modal.Body>
        </Modal>
        </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
</>
    )
}
