import React, { Component } from "react";
class Private extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      isError: {
        fullname: "",
      },
    };
  }
 

  validation = (fullname) => {
    if (this.state.fullname !== "cwsp2022" ) {
      return alert("Contraseña no válida");
    }else if(this.state.fullname === "cwsp2022"){
        window.location.href = '/zonaprivadasocios26H99lLM'
    }
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "fullname":
        isError.fullname =
          value.length < 2
            ? "Debes completar este campo con mínimo 2 caracteres"
            : "";
        break;
        default:
    }

    this.setState({
      isError,
      [name]: value,
    });
  };

  handleFormSubmit = async (event) => {
    try {
      event.preventDefault();
      await this.checkForErrors();
      this.setState({
        fullname: "",
        checkbox: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { fullname} = this.state;

    return (
      <div>
        <form className="private-modal" onSubmit={this.handleFormSubmit}>
          <div className="labels-align">
            <input
            className="input-private"
              type="text"
              name="fullname"
              placeholder="   Indica el password"
              value={fullname}
              onChange={(e) => this.handleChange(e)}
              required
            />
            {this.state.isError.fullname.length > 0 && (
              <span>{this.state.isError.fullname}</span>
            )}
          </div>
          <div className="align-button">
            <div>
              <div>
                <input
                  onClick={() => {
                    this.validation(fullname);
                  }}
                  className="button-modal"
                  type="submit"
                  value="Acceder"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Private;