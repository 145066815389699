import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Nav from "./components/Nav/NavWind";
import NavSide from "./components/Nav/NavSide";
import WApp from "./utils/WhatsApp.jsx";
import Meteo from "./components/Meteo/Meteo";
import Socios from "./components/Socios/Socios";
import Private from "./components/Private/Private";
import Sociosprivado from "./components/Private/Sociosprivado";
import Staff from "./components/Staff/Staff";
import About1 from "./components/About/About1";
import Gallery from "./components/Gallery/Gallery";
import Wind from "./components/ActNaut/Wind";
import Yoga from "./components/ActNaut/Yoga";
import Campus from "./components/ActNaut/Campus";
import Campus2 from "./components/ActNaut/Campus2";
import Grupos from "./components/ActNaut/Grupos";
import Page404 from "./utils/Page404";
import Servicios from "./components/Servicios/Servicios";
import Vr from "./utils/Vr";
import Qr from "./components/Qr/Qr";
import CondicionesDeUso from "./components/Legal/CondicionesDeUso";
import AvisoLegal from "./components/Legal/AvisoLegal";
import Privacidad from "./components/Legal/Privacidad";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div>
      <Nav />
      <NavSide />{" "}
      <CookieConsent
        location="bottom"
        buttonText="Acepto"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#1E8696",color:"white"}}
        buttonStyle={{ fontSize: "15px", borderRadius: "21px" }}
        expires={150}
      >
        Al navegar por esta web acepta los términos y condiciones de uso. Esta web usa Cookies propias
      </CookieConsent>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/defaultsite" element={<Home />} />
          <Route exact path="/servicios" element={<Servicios />} />
          <Route exact path="/cursoWindSurf" element={<Wind />} />
          <Route exact path="/yoga" element={<Yoga />} />
          <Route exact path="/campus" element={<Campus2/>} />
          <Route exact path="/campus2" element={<Campus/>} />
          <Route exact path="/grupos" element={<Grupos />} />
          <Route exact path="/meteo" element={<Meteo />} />
          <Route exact path="/socio" element={<Socios />} />
          <Route exact path="/staff" element={<Staff />} />
          <Route exact path="/sobrenosotros" element={<About1 />} />
          <Route exact path="/galeria" element={<Gallery />} />
          <Route exact path="/zonaPrivada" element={<Private />} />
          <Route
            exact
            path="/zonaprivadasocios26H99lLM"
            element={<Sociosprivado />}
          />
          <Route
            exact
            path="/CondicionesDeUso"
            element={<CondicionesDeUso />}
          />
          <Route exact path="/AvisoLegal" element={<AvisoLegal />} />
          <Route exact path="/PoliticaPrivacidad" element={<Privacidad />} />
          <Route exact path="/tourVirtual" element={<Vr />} />
          <Route exact path="/inscripciones" element={<Qr />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </Router>
      <WApp />
      <Footer />
    </div>
  );
}

export default App;
