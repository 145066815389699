import React from 'react'

export default function AvisoLegal() {
  return (
    <div className="privacy-p margin-legacy">
      <h1 className="title-socio gallery-align-h1">Aviso legal</h1>
      <h5 className="head-serv2 subtitle-socio">Sistema de Videovigilancia.</h5>

      <p>
        Recordamos a todos los usuarios y asociados de la presencia de cámaras
        de seguridad de videovigilancia conectada a la central de alarmas en el
        interior y exterior perimetral del club, al igual de la instalación
        existentes de carteles informativos. Que sus datos personales están
        incluidos en un fichero titularidad del Club Windsurf de Santa Pola, y
        que son tratados de conformidad con la normativa reguladora de
        protección de datos con la finalidad de realizar actividades de
        marketing, promoción y comercialización de sus productos o servicios y
        de comunicar los eventos que se organicen. Puede ejercer sus derechos de
        acceso, rectificación, cancelación y oposición, en los términos
        recogidos en la Ley Orgánica de Protección de Datos de Carácter
        Personal, dirigiéndose al Club de Windsurf Santa Pola a través de correo
        electrónico a info@windsurfsantapola.com
      </p>
    </div>
  )
}
