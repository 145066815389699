import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div>
      <div className="pg-footer">
        <footer className="footer">
          <svg
            className="footer-wave-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              className="footer-wave-path"
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg>
          <div className="footer-content">
            <div className="footer-content-column">
              <div className="footer-logo">
                <a className="footer-logo-link" href="/">
                  <span className="hidden-link-text">LOGO</span>
                  <img src="/new/logo-web.png" alt="logo" className="logo-footer" />
                </a>
                
              </div>
            </div>
            <div className="footer-content-column">
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Club WS Santa POla</h2>
                <ul id="menu-company" className="footer-menu-list">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a
                      href="https://www.google.com/maps/dir//club+de+windsurf+santa+pola/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd624ce3a9102141:0x60229b4a8258ff6d?sa=X&ved=2ahUKEwiklPKamqP4AhUGwoUKHQnpAvYQ9Rd6BAheEAU"
                      target="_self"
                    >
                      Ubicación
                    </a>
                  </li>

                  <li>
                    <a href="mailto:info@windsurfsantapola.com" target="_self">
                      info@windsurfsantapola.com
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="tel:640326402" target="_self">
                      +34 640326402
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Legal</h2>
                <ul id="menu-legal" className="footer-menu-list">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                    <a href="/politicaPrivacidad" className="underline">
                      Privacidad
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="/CondicionesDeUso" className="underline">
                      Términos de uso
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="/AvisoLegal" className="underline">
                      Aviso Legal
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-content-column">
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Links rápidos</h2>
                <ul id="menu-quick-links" className="footer-menu-list">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom">
                    <a
                      rel="noopener noreferrer"
                      href="/socio"
                      className="underline"
                    >
                      Socio
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom">
                    <a
                      rel="noopener noreferrer"
                      href="/cursoWindSurf"
                      className="underline"
                    >
                      Curso Windsurf
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="/meteo" className="underline">
                      Zona Meteo
                    </a>
                  </li>
                </ul>
               
              </div>
              <div className="footer-menu">
                <h2 className="footer-menu-name"> HORARIO</h2>
                <ul id="menu-legal" className="footer-menu-list1">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                  <p className="color-horario">10:00 -14:00</p>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                   <p className="color-horario">16:00 -18:00</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-content-column margin-btn-fot">
           < div className="footer-call-to-action btn-none">
                <h2 className="footer-call-to-action-title marg-12"> Vamos a hablar</h2>
                <a
                  className="footer-call-to-action-button button blue marg-btn-1"
                  href="mailto:info@windsurfsantapola.com"
                  target="_self"
                >
                  {" "}
                  Mandar Email{" "}
                </a>
              </div>
              <div className="footer-call-to-action">
                <h2 className="footer-call-to-action-title marg-12"> Llámanos</h2>
                <a
                  className="footer-call-to-action-button button blue"
                  href="tel:640326402"
                  target="_self"
                >
                  {" "}
                  Llamar
                </a>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="footer-copyright-wrapper">
              <p className="footer-copyright-text">
                <a
                  className="footer-copyright-link"
                  href="https://kowalski-61481.web.app/"
                  target="_self"
                >
                  {" "}
                  ©2022. | Designed By: Kowalski Design. | All rights reserved.{" "}
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
