import React from 'react'
import './Meteo.css'

export default function Meteo() {
    return (
        <div>
                    <h1 className="title-socio gallery-align-h1">Zona meteo</h1>

             <div className="align-meteo2">

<div className='align-f-div'>
            <iframe title="1" id="meteo1" src='https://www.weatherlink.com/embeddablePage/show/ccf56be27ea64f2c9e7e1335982294de/wide'></iframe>
            <div className="align-f2-div">
        <iframe title="2" className="meteo2" scrolling="no" src="https://www.avamet.org/widgets/widget-l.php?codi=c33m121e05&amp;size=161&amp;idioma=es"></iframe>
       <iframe title="3" className="meteo2" scrolling="no" src="https://www.avamet.org/widgets/widget-l.php?codi=c33m121e06&amp;size=161&amp;idioma=es"></iframe>
       </div>
        {/* <iframe title="4" className='cloud' src="https://www.tutiempo.net/s-widget/full/7783/ca5" scrolling="no"></iframe> */}
        </div>
        <div className='button-contain'>
            <a href="https://www.windguru.cz/48840"> <img className='btnSize' src='/BOTON PREVISION.png' alt="button"/></a>
            <a href="https://www.comunitatvalenciana.com/es/alacant-alicante/santa-pola/webcams/santa-pola-gran-playa-vela"> <img className='btnSize' src='/button-01.png' alt="button"/></a>
            <a href="https://www.weatherlink.com/embeddablePage/show/e55c9ecfb2a54b32910d2812ab72b0c9/summary"> <img className='btnSize' src='/button-02.png' alt="button"/></a>
            </div>
        </div>
        </div>
    )
}
