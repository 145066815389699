import React from 'react';

export default function Servicios() {
    return (
        <div>
                  <h1 className="head-serv title-socio">Servicios</h1>
      <h5 className="head-serv2 subtitle-socio">Disfruta de 4 actividades por el precio de 1. Con la cuota<br/> anual puedes disfrutar de:</h5>
       
        <div className='body-serv-card'>
          <figure class="snip1336">
  <img className='width-serv-image' src="/new/kayak.png" alt="sample87" />
  <figcaption>
    <img src="https://i.postimg.cc/RFpwMYrd/kayak.png" alt="profile-sample4" class="profile" />
    <h2>KAYAK</h2>
    <p>Disfruta de la costa de Santa Pola dándote un paseo en kayak individual y doble. Esta zona te permite parar y darte un baño. Perfecto para desconectar solo o con tu familia.</p>
  </figcaption>
</figure>
<figure class="snip1336 hover"><img className='width-serv-image' src="https://lpwindsurf.com/wp-content/uploads/2021/04/2021-Tabou-gallery-DaBomb-03.jpg" alt="sample74" />
  <figcaption>
    <img  src="https://i.postimg.cc/KvPrTJtG/windsurf.png" alt="profile-sample2" class="profile" />
    <h2>WINDSURF</h2>
    <p>Una manera cómoda y sencilla para practicar este deporte náutico. Perfecto para seguir aprendiendo y coger confianza después de realizar un curso con nosotros.</p>
  </figcaption>
</figure>
<figure class="snip1336"><img className='width-serv-image' src="/new/gym.png" alt="sample69" />
  <figcaption>
    <img src="https://i.postimg.cc/0Qnc7nTK/gym.png" alt="profile-sample5" class="profile" />
    <h2>GIMNASIO</h2>
    <p>
Realiza tu entreno a pie de playa y con vistas al mar. Con tu llave y chip puedes entrenar a la hora que mejor te venga y finalizar el entreno con un baño revitalizador.</p>
  </figcaption>
</figure>
<figure class="snip1336"><img className='width-serv-image' src="https://secure.img1-fg.wfcdn.com/im/34011371/compr-r85/1928/192804629/inflatable-stand-up-paddle-board-106x-32x6-with-free-premium-sup-accessories-backpack-non-slip-deck-bonus-waterproof-bag-leash-paddle-and-hand-pump.jpg" alt="sample69" />
  <figcaption>
    <img src="https://i.postimg.cc/Px0zW76x/paddlesurf.png" alt="profile-sample5" class="profile" />
    <h2>PADDLESURF</h2>
    <p>
Practica este deporte que aporta tantos beneficios. Disfruta del paseo y de las vistas. Es una buena forma para iniciar alguna actividad náutica, y es apta para cualquier nivel.</p>
  </figcaption>
</figure>
        </div>
        </div>
    )
}
