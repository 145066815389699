import React from "react";
import "./Staff.css";
export default function Staff() {
  return (
    <div>
      <section id="team" className="team content-section">
        <div>
          <div className="row text-center">
            <div className="col-md-12">
              <h1 className="head-serv title-socio">STAFF</h1>
            </div>

            <div>
              <div className="row">
               
                <div className="col-md-4">
                  <div className="team-member">
                    <figure>
                      <img
                        src="/daniel.jpeg"
                        alt="daniel"
                        className="img-responsive"
                      />
                      <figcaption>
                        <p>
                          <ul>
                            <li>Coordinador de Escuela</li>
                            <li>Técnico deportivo de vela</li>
                          </ul>
                        </p>
                        <ul>
                          <li>
                            <a href="">
                              <i className="fa fa-facebook fa-2x"></i>
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fa fa-twitter fa-2x"></i>
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fa fa-linkedin fa-2x"></i>
                            </a>
                          </li>
                        </ul>
                      </figcaption>
                    </figure>
                    <h4>DANIEL SEMPERE NAVARRO</h4>
                    <p>Coordinador</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="team-member">
                    <figure>
                      <img
                        src="/ruben.jpeg"
                        alt="ruben"
                        className="img-responsive"
                      />
                      <figcaption>
                        <p>
                          <ul>
                            <li>Comodoro</li>
                            <li>Auxiliar técnico deportivo de vela</li>
                          </ul>
                        </p>
                        <ul>
                          <li>
                            <a href="">
                              <i className="fa fa-facebook fa-2x"></i>
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fa fa-twitter fa-2x"></i>
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fa fa-linkedin fa-2x"></i>
                            </a>
                          </li>
                        </ul>
                      </figcaption>
                    </figure>
                    <h4>RUBEN LOPEZ CHAVALOY</h4>
                    <p>Comodoro</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="team-member">
                    <figure>
                      <img
                        src="/zaira.jpeg"
                        alt="zaira"
                        className="img-responsive"
                      />
                      <figcaption>
                        <p>
                          <ul>
                            <li>Administración</li>
                            <li>Auxiliar técnico deportivo de vela</li>
                          </ul>
                        </p>
                        <ul>
                          <li>
                            <a href="">
                              <i className="fa fa-facebook fa-2x"></i>
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fa fa-twitter fa-2x"></i>
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fa fa-linkedin fa-2x"></i>
                            </a>
                          </li>
                        </ul>
                      </figcaption>
                    </figure>
                    <h4>ZAIRA MARTINEZ MARTINEZ</h4>
                    <p>Administración</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// import React from 'react'
// import './Staff.css'
// export default function Staff() {
//     return (
//         <div>

// <section id="team" className="team content-section">
//   <div >
//     <div className="row text-center">
//       <div className="col-md-12">
//         <h1 className="head-serv title-socio">STAFF</h1>
//       </div>

//       <div >
//         <div className="row">
//           <div className="col-md-4">
//             <div className="team-member">
//               <figure>
//                 <img src="/daniel.jpeg" alt="daniel"  />
//                 <figcaption>
//                   <p><ul>
//                           <li>Coordinador de Escuela</li>
//                           <li>Técnico deportivo de vela</li>
//                         </ul></p>
//                   <ul>
//                     <li><a href=""><i className="fa fa-facebook fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-twitter fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-linkedin fa-2x"></i></a></li>
//                   </ul>
//                 </figcaption>
//               </figure>
//               <h4>DANIEL SEMPERE NAVARRO</h4>
//               <p>Coordinador</p>
//             </div>
//           </div>

//           <div className="col-md-4">
//             <div className="team-member">
//               <figure>
//                 <img src="/ruben.jpeg" alt="ruben"  />
//                 <figcaption>
//                   <p>
//                   <ul>
//                           <li>Comodoro</li>
//                           <li>Auxiliar técnico deportivo de vela</li>
//                         </ul>
//                   </p>
//                   <ul>
//                     <li><a href=""><i className="fa fa-facebook fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-twitter fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-linkedin fa-2x"></i></a></li>
//                   </ul>
//                 </figcaption>
//               </figure>
//               <h4>RUBEN LOPEZ CHAVALOY</h4>
//               <p>Comodoro</p>
//             </div>
//           </div>

//           <div className="col-md-4">
//             <div className="team-member">
//               <figure>
//                 <img src="/zaira.jpeg" alt="zaira" className="img-responsive" />
//                 <figcaption>
//                   <p>
//                   <ul>
//                           <li>Administración</li>
//                           <li>Auxiliar técnico deportivo de vela</li>
//                         </ul>
//                   </p>
//                   <ul>
//                     <li><a href=""><i className="fa fa-facebook fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-twitter fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-linkedin fa-2x"></i></a></li>
//                   </ul>
//                 </figcaption>
//               </figure>
//               <h4>ZAIRA MARTINEZ MARTINEZ</h4>
//               <p>Coordinadora</p>
//             </div>
//             <div className="col-md-">
//             <div className="team-member">
//               <figure>
//                 <img src="/daniel.jpeg" alt="daniel"  />
//                 <figcaption>
//                   <p><ul>
//                           <li>Coordinador de Escuela</li>
//                           <li>Técnico deportivo de vela</li>
//                         </ul></p>
//                   <ul>
//                     <li><a href=""><i className="fa fa-facebook fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-twitter fa-2x"></i></a></li>
//                     <li><a href=""><i className="fa fa-linkedin fa-2x"></i></a></li>
//                   </ul>
//                 </figcaption>
//               </figure>
//               <h4>DANIEL SEMPERE NAVARRO</h4>
//               <p>Coordinador</p>
//             </div>
//           </div>

//           </div>

//         </div>
//       </div>

//     </div>
//   </div>
// </section>
// </div>

//     )
// }
