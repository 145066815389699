import React, { useState } from "react";
import {
  Navbar,
  Container,
  Offcanvas,
  Nav,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Private from "../Private/Private";

export default function NavSide() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Navbar className="resp-nav1" bg="light" expand={false}>
        <Container fluid>
          <Navbar.Brand href="/">
            <img src="/new/logo-web.png" width="120" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <div className="sideBar">
                <Navbar.Brand href="/">
                  <img className="logo-side-marg" src="/new/logo-web.png" width="170" alt="logo" />
                </Navbar.Brand>
                <Nav.Link id="align-haztesocio" href="/socio">
                  Hazte Socio
                </Nav.Link>
                <Nav.Link href="/servicios">Servicios Socio</Nav.Link>
                <NavDropdown
                  title="Actividades Naúticas"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/cursoWindSurf">
                    Curso Windsurf
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/yoga">SUP Yoga</NavDropdown.Item>
                  <NavDropdown.Item href="/grupos">Grupos</NavDropdown.Item>
                  <NavDropdown.Item href="/campus">Campus</NavDropdown.Item>

                </NavDropdown>
                <Nav.Link href="/meteo">Zona Meteo</Nav.Link>
                <NavDropdown title="Sobre Nosotros">
                  <NavDropdown.Item href="/sobrenosotros">
                    Quiénes somos
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/staff">Staff</NavDropdown.Item>
                  <NavDropdown.Item href="/galeria">Galería</NavDropdown.Item>
                  <NavDropdown.Item>
                    <Button
                      variant="primary"
                      className="btn-private"
                      onClick={handleShow}
                    >
                      Zona de socios
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Accede a la zona de Socios</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Private />
                      </Modal.Body>
                    </Modal>
                  </NavDropdown.Item>
                </NavDropdown>
                <div className="vr-align">
                  <Nav.Link href="https://www.instagram.com/windsurfsantapola/?igshid=YmMyMTA2M2Y=">
                    <img src="/insta.png" alt="insta" width="40" />
                  </Nav.Link>
                  <Nav.Link href="tourVirtual">
                    <img src="/vr.png" alt="insta" width="110" />
                  </Nav.Link>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
