import React from "react";
import "./ActNaut.css";
import { Card, Accordion } from "react-bootstrap";

export default function Campus2() {
  return (
    <div>
      <div className="containerAN">
        <section className="banner-module">
          <div className="banner banner-four-campus">
            <span className="headline">
              CAMPUS <br />
              AZUL
            </span>
          </div>
          <div className="banner banner-three-title">
            <h1 className="title-socio title-none">Campus azul</h1>
            <h5 className="head-serv2 subtitle-socio1 title-none">
              Solo para socios familiares
            </h5>
          </div>

          <div className="banner banner-one">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">
                  SEMANA / <br />
                  MES
                </Card.Title>
                <Card.Text>
                  <ul className="list-act campus-resp">
                    <li>
                      <b>Duración: </b>Lunes a Viernes{" "}
                    </li>
                    <div className="align-tachado">
                      <li className="price tachado">75€</li>
                      <li className="price">ㅤ52.5€</li>
                    </div>
                    <li>
                      <b>Duración: </b>Julio o Agosto
                    </li>
                    <div className="align-tachado">
                      <li className="price tachado">275€</li>
                      <li className="price">ㅤ192.5€</li>
                    </div>
                    <li>*Solo para socios familiares</li>
                  </ul>
                </Card.Text>
                <a
                  className="button-act-align"
                  href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=4#"
                >
                  {" "}
                  <button className="button-act">Solicitud</button>
                </a>
              </Card.Body>
            </Card>
          </div>

          <div className="banner banner-two">
            <Card>
              <Card.Body>
                <Card.Title className="title-act">
                  TEMPORADA <br />
                  DE VERANO
                </Card.Title>
                <Card.Text>
                  <ul className="list-act campus-resp">
                    <li>
                      <b>Duración: </b>La temporada de verano incluye los meses
                      de Julio y Agosto, aprende y disfruta con nosotros.
                    </li>

                    <div className="align-tachado">
                      <li className="price tachado">440€</li>
                      <li className="price">ㅤ308€</li>
                    </div>
                    <li>*Solo para socios familiares</li>
                  </ul>
                </Card.Text>

                <a
                  className="button-act-align"
                  href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=4#"
                >
                  {" "}
                  <button className="button-act">Solicitud</button>
                </a>
              </Card.Body>
            </Card>
          </div>

          <div className="clear-fix"></div>

          <div className="banner banner-three">
            <Accordion className="accordion-margin" defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {" "}
                  <Card.Title className="title-act-1">
                    {" "}
                    Descripción Campus
                  </Card.Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <p className="p-act-1">* Plazas limitadas</p>
                        <p className="p-act-1">
                          * Respetar normas vigentes de{" "}
                          <a href="https://www.mitma.gob.es/marina-mercante/nautica-de-recreo/normas-de-seguridad-y-recomendaciones">
                            capitanía marítima
                          </a>{" "}
                          y{" "}
                          <a href="http://www.salvamentomaritimo.es/mejora-tu-seguridad">
                            salvamento.
                          </a>
                        </p>

                        <p className="p-act-1">
                          Se distribuye en dos actividades al día con un
                          descanso para almorzar, beber agua y ponerse crema.
                        </p>
                        <p className="p-act-1">
                          Hay diversas actividades, tales como Kayak, Windsurf,
                          Paddle Surf, Big Sup y juegos en la arena o agua.
                        </p>
                        <p className="p-act-1">
                          Nos adaptamos al ritmo de aprendizaje de cada persona.
                        </p>
                        <p className="p-act-1">
                          La realización de las actividades puede variar en
                          función de la meteorología.
                        </p>
                        <br />
                        <ul className="list-act">
                          <h3 className="h3-act p-act-3">
                            ¿Qué incluye la actividad?
                          </h3>

                          <li className="p-act-2">
                            Material necesario para el desarrollo de la
                            actividad
                          </li>
                          <li className="p-act-2">Monitores titulados</li>
                          <li className="p-act-2">
                            Clases teóricas y prácticas
                          </li>
                          <li className="p-act-2">Licencia de Escuela</li>
                        </ul>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </section>
      </div>
    </div>
  );
}
