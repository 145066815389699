import React from 'react';
import './ActNaut.css';
import {Card,Accordion} from 'react-bootstrap'

export default function Wind() {
    return (
        <div>
<div className="containerAN">
        <section className="banner-module">
        <div className="banner banner-four">

<span className="headline">CURSO DE <br/>WINDSURF</span>
</div>
<div className="banner banner-three-title">
  
   
<h1 className="title-socio title-none">
CURSO DE WINDSURF
 </h1>
            </div>

            <div className="banner banner-one">

            <Card >
  <Card.Body>
    <Card.Title className="title-act">CURSO DE <br/>4 HORAS</Card.Title>
    <Card.Text>
      <ul className="list-act">
        <li><b className='duracion'>Duración:</b><br/> 2 días – 4 horas</li>
        <li><b className='duracion'>Tamaño grupo:</b><br/> 1- 8 pers.</li>
        <li className="price"><b>75€</b> </li>
      </ul>
    </Card.Text>
    <a className="button-act-align" href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=2"> <button className="button-act" >Solicitud</button></a>
  </Card.Body>
</Card>
{/* <img className="oferta" src="/oferta.png" alt="oferta" width="120"/> */}
<p className="astAN">* En todas las actividades a los asociados/as individuales se les aplicará un descuento del 20%, y a los familiares un 30% <a href="/socio"> Hazte socio aquí</a></p>
            </div>


            <div className="banner banner-two">
            <Card>
            <Card.Body>
  <Card.Title className="title-act">CURSO DE <br/>10 HORAS</Card.Title>
    <Card.Text>
      <ul className="list-act">
        <li><b className='duracion'>Duración:</b><br/> 5 días – 10 horas</li>
        <li><b className='duracion'>Tamaño grupo:</b><br/> 1- 8 pers.</li>
        <li className="price"><b>150€</b></li>
      </ul>
    </Card.Text>
    <a className="button-act-align" href="https://windsurfsantapola.playoffinformatica.com/PanellActivitatsWeb.php?idNivell=2"> <button className="button-act" >Solicitud</button></a>
  </Card.Body>
</Card>
<p className="astAN">* En todas las actividades a los asociados/as individuales se les aplicará un descuento del 20%, y a los familiares un 30% <a href="/socio"> Hazte socio aquí</a></p>
            </div>
            
            <div className="clear-fix"></div>
            

            <div className="banner banner-three">
            <Accordion defaultActiveKey="1">
  <Accordion.Item eventKey="0">
    <Accordion.Header ><Card.Title className="title-act-1"> Descripción del Curso</Card.Title></Accordion.Header>
    <Accordion.Body>
    <Card>
  <Card.Body>
    <Card.Text>
    <p className="p-act-1">• Respetar normas vigentes de <a href="https://www.mitma.gob.es/marina-mercante/nautica-de-recreo/normas-de-seguridad-y-recomendaciones">capitanía marítima</a> y <a href="http://www.salvamentomaritimo.es/mejora-tu-seguridad">salvamento.</a></p>
      <p className="p-act-1">• El curso de <b>4 horas</b> tiene una duración de <b>2 días.</b></p>
      <p className="p-act-1">• El curso de <b>10 horas</b> tiene una duración de <b>5 días.</b></p>
      <p className="p-act-1">Al día se practica durante 2 horas. No es necesario que los días sean consecutivos.</p>
      <p className="p-act-1">Podrás escoger turno de mañana (de 11:30 a 13:30) o de tarde (de 15:30 a 17:30 o de 17:30 a 19:30), para ello consulta disponibilidad al personal.</p>
      <h3 className="h3-act p-act-4">¿Qué aprenderás?</h3>
      <p className="p-act-1">Mediante este curso aprenderás las nociones básicas de este deporte para poder practicarlo de forma autónoma.
En primer lugar, nos familiarizaremos con el material en la arena, aprendiendo a colocarnos, el manejo de la vela y los rumbos (nociones teóricas). Luego practicaremos a mantenernos de pie ya en el agua. Con el curso de 2 días el objetivo es realizar un giro básico y volver al punto de salida. Este curso es una toma de contacto con el deporte. Sin embargo, con el curso de 5 días se realizará los diferentes tipos de giros y rumbos, y a tener autonomía.</p>
<p className="p-act-1">*Nos adaptamos al ritmo de aprendizaje de cada persona.</p>
<p className="p-act-1">*La realización de los cursos puede variar en función de la meteorología.</p>
<h3 className="h3-act p-act-3">¿Qué incluye la actividad?</h3>
<ul className="list-act">
  <li className="p-act-1">• Material necesario para el desarrollo de la actividad</li>
  <li className="p-act-1">• Monitores titulados</li>
  <li className="p-act-1">• Clases teóricas y prácticas</li>
  <li className="p-act-1">• Licencia de Escuela</li>
</ul>


    </Card.Text>
  </Card.Body>
</Card>
    </Accordion.Body>
  </Accordion.Item>
  </Accordion>
            </div>

    
           
        </section>
    </div>
        </div>
    )
}
