import React from 'react'
import Cards from '../Cards/Cards'

export default function Socios() {
    return (
        <div>
            <Cards/>
        </div>
    )
}
